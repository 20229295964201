import { CustomFlex } from '@design-system/src/components/CustomFlex'
import { AuthorsPreviewType } from 'next-public-site/app/sites/[site]/[[...page]]/_utils/typescript-utils'
import Link from 'next/link'
import React from 'react'

import { AuthorPreview } from './AuthorPreview'

export const defaultSize = 'small'
const defaultDirection = 'column'
export const defaultPriority = false

export const AuthorsPreview = React.forwardRef<HTMLDivElement, AuthorsPreviewType & { priority?: boolean }>(
  (
    {
      authors,
      size = defaultSize,
      direction = defaultDirection,
      doNotTransformImageURL = false,
      titleMustBeVisible = false,
      priority = defaultPriority,
    },
    forwardRef,
  ) => {
    return (
      <CustomFlex
        direction={{
          base: 'column',
          bp3: direction === 'row' ? 'row' : 'column',
        }}
        gap={size === 'small' ? '2' : '6'}
        ref={forwardRef}>
        {authors.map((el, index) =>
          el.href ? (
            <Link href={el.href} key={el.href + index} prefetch={true} passHref>
              <AuthorPreview
                hasLink={!!el.href}
                overTwoAuthors={authors.length > 2}
                doNotTransformImageURL={doNotTransformImageURL}
                type={size}
                titleMustBeVisible={titleMustBeVisible}
                priority={priority}
                {...el}
              />
            </Link>
          ) : (
            <AuthorPreview
              overTwoAuthors={authors.length > 2}
              doNotTransformImageURL={doNotTransformImageURL}
              type={size}
              key={el.id}
              titleMustBeVisible={titleMustBeVisible}
              {...el}
            />
          ),
        )}
      </CustomFlex>
    )
  },
)
AuthorsPreview.displayName = 'AuthorsPreview'
